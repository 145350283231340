/* eslint-disable camelcase */

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

const fr = {
  demo: {
    title: `France`,
    introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
  },
  docs: {
    hi: `Bonjour`,
    description: `Verifiez nos documents.`,
    documentation: `Documentation`,
    contact_us: `Contactez-nous`,
    starleader_values: `Valeurs de StarLeader`,
    update_your_cookies_settings: `Mettre à jour vos paramètres de cookies`,
    cookiePolicy: `Cookies`,
    legalNotice: `Mentions légales`,
    privacyPolicy: `Confidentialité`,
  },
  menu: {
    Home: `Accueil`,
    Summary: `Résumé`,
    Reviews: `Avis`,
    Goals: `Objectifs`,
    Campaigns: `Campagnes`,
    Analytics: `Analytique`,
    Admin: `Administration`,
  },
  home_page: {
    no_reviews_yet: `Pas encore d'avis. Demandez à vos employés de vous évaluer.`,
    step_1: `Manager Auto-avis, Employé/Peer avis .`,
    step_2: `Définir un objectif et 3 actions par le manager après discussion avec le N+1 et l'équipe, idéalement`,
    step_3: `Avis sur la progression de l'objectif`,
    step_4: `Fin de la campagne`,
    welcome_msg: `Bienvenue`,
    welcome_text: `Nous sommes là pour vous soutenir à chaque étape. Faisons de ce voyage un succès ensemble !`,
    campaign_step: `Guide de campagne étape par étape`,
    no_campaign: `Aucune campagne disponible. Contactez votre service RH pour en créer une et commencer les évaluations!`,
    click_here: `Cliquez ici`,
    create_one: `pour en créer une.`,
    hr_home_page_intro_1: `Rien a faire ici, Visiter`,
    hr_home_page_intro_2: `la page Analytiques`,
    hr_home_page_intro_3: `pour voir les statistiques, ou`,
    hr_home_page_intro_4: `la page Campagnes`,
    hr_home_page_intro_5: `pour voir les détails de la campagne en cours`,
  },
  reviews_page: {
    no_self_reviews_yet: `Aucun auto-avis n'a encore été complété.`,
    no_reviews_yet: `Aucun avis n'a encore été complété.`,
    no_reviews_visible: `Vos avis seront visible à la fin de la campagne étape avis`,
    my_reviews: `Mes avis`,
    self_reviews: `Auto-avis`,
    submitted_reviews: `avis soumis`,
  },
  survey_summary: {
    self_review: `Auto-avis`,
    review: `Avis`,
    peer_review: `Peer-Avis`,
    progress_goal_survey: `Goal-Avis`,
    recommendation: `Recommendation`,
    trust: `Confiance`,
    open_review: `ouvrir`,
    resume_review: `reprendre`,
  },
  categories_tooltip: {
    care: `VISION: envisions a desired future and generates excitement by conveying a compelling, shared vision`,
    coach: `COMMUNICATION: communicates well and frequently provides explanations to stakeholders`,
    develop: `DEVELOPMENT: fosters a development culture by encouraging all team members to embrace a growth mindset and taking actions daily to develop themselves and those around them`,
    deliver: `TRUST: influences others by building credibility and positive relationships rather than relying on authority to generate momentum around Shape priorities`,
    lead: `PROBLEM SOLVING: continuous improvement and the use of proven, problem-solving methodologies`,
  },
  reviews_one_pager: {
    manager_reaction: `réaction de votre manager:`,
    comment: `commentaire:`,
    peer_comment: `peer report commentaires:`,
    manager_comment: `direct report commentaires:`,
  },
  survey: {
    comment_label: `Ajouter votre commentaire (Obligatoire)`,
    comment_tooltip: ` Veuillez être constructif et respectueux. Tout contenu raciste, sexiste, discriminatoire ou offensant sera supprimé. Utilisez un langage le plus neutre possible pour maintenir l'anonymat. Suite à la notification d'une tierce partie, nous pourrions retirer tout contenu inapproprié.`,
    submit: `Soumettre`,
    start_survey: `Salut! Il vous faudra maximum 10 minutes pour compléter cette campagne d'avis qui est anonyme.`,
    survey_end: `Félicitations pour votre participation ! Votre évaluation a été soumise. Jetons un œil au résumé.`,
    rating1: `Oups, il semble que votre manager ait des améliorations à apporter dans ce comportement... Quelle action recommanderiez-vous à votre manager pour s'améliorer ?`,
    rating5: `Wow ! Il semble que votre manager excelle dans ce comportement. Ce serait bien de le faire savoir à votre manager en fournissant un exemple concret. Cela sera sûrement apprécié !`,
    click_icon_bellow: `Cliquez sur l'icône ci-dessous pour continuer`,
    click_star: `Cliquez sur une étoile pour continuer`,
    add_comment: `Ajoutez votre commentaire ici (Optionnel, sauf pour les notes de 1 et 5 étoiles)`,
    add_comment_progress_goal: `Ajoutez votre commentaire ici (Optionnel)`,
    submitted: `Merci ! Votre avis a été soumis avec succès`,
    progress_goal: `Pensez-vous que votre manager s'est amélioré sur ce comportement?`,
  },
  discuss_chat: {
    discuss: `Discuter`,
    chat_tooltip: `Ouvrez la fenêtre de discussion. Si un nouveau message est reçu, une notification sera envoyée.`,
    chat_label: `Ajouter votre message...`,
  },
  app: `application`,
  user: `utilisateur`,
  list: `liste`,
  edit: `éditer`,
  shop: `boutique`,
  blog: `blog`,
  post: `poste`,
  mail: `e-mail`,
  chat: `discuter`,
  cards: `cartes`,
  posts: `des postes`,
  create: `créer`,
  kanban: `kanban`,
  general: `général`,
  banking: `bancaire`,
  booking: `réservation`,
  profile: `profil`,
  account: `compte`,
  product: `produit`,
  invoice: `facture`,
  details: `détail`,
  checkout: `vérifier`,
  calendar: `calendrier`,
  analytics: `analytique`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `niveau menu`,
  menu_level_2a: `niveau menu 2a`,
  menu_level_2b: `niveau menu 2b`,
  menu_level_3a: `niveau menu 3a`,
  menu_level_3b: `niveau menu 3b`,
  menu_level_4a: `niveau menu 4a`,
  menu_level_4b: `niveau menu 4b`,
  item_disabled: `élément désactivé`,
  item_label: `étiquette de l'article`,
  item_caption: `légende de l'article`,
  item_external_link: `lien externe de l'article`,
  description: `la description`,
  other_cases: `autres cas`,
  item_by_roles: `élément par rôles`,
  only_admin_can_see_this_item: `seul l'administrateur peut voir cet élément`,
  reviews: `avis`,
  review: `avis`,
  self_review: `auto-avis par rapport à votre équipe`,
  campaign: `campagne`,
  me: `Moi`,
  other_managers: `Autres managers`,
  recommend_you: `Vous recommandent`,
  trust_you: `Vous font confiance`,
  employee: `collaborateur`,
  manager: `manager`,
  hr: `RH`,
  summary: `Résumé`,
  rating_answer: `réponses d'avis`,
  from_your_team: `de votre équipe`,
  from_your_peers: `de vos peers`,
  comparaison: `comparaison`,
  no_results_yet: `Pas de résultats.`,
  campaign_page: {
    campaign: `Campagne`,
    set_goal: `Fixer un objectif`,
    review_period: `Période d'avis`,
    one_campaign: `Il y a déjà une campagne en cours. Une seule campagne peut être active à la fois.`,
    no_campaign: `Il n'y a actuellement aucune campagne.`,
    create_campaign: `Créer une nouvelle campagne`,
    start_campaign: `Démarrer`,
  },
  values_page: {
    title: `La confiance se gagne en gouttes et se perd en litres`,
    author: `Jean-Paul Satre`,
    section1: `La relation entre le manager et le collaborateur est clé dans notre vie professionnelle. Que vous soyez employé ou manager, il est dans l'intérêt de chacun de placer la qualité de cette relation au cœur de nos interactions quotidiennes.`,
    section2: `En tant que collaborateur, StarLeader vous donne l'opportunité de vous exprimer anonymement et de manière constructive sur le comportement de votre manager. Votre opinion compte!`,
    section3: `Devenez un agent de changement en aidant votre manager à devenir une meilleure version de lui-même, c'est l'expérience unique que nous vous proposons avec StarLeader.`,
    section4: `En tant que manager, StarLeader est un outil pour améliorer votre conscience de soi, progresser et développer la confiance au sein de votre équipe! Devenez un meilleur StarLeader et donnez-vous plus de chances de grandir au sein de l'entreprise, c'est l'expérience unique que nous vous offrons avec StarLeader.`,
    section5: `En faisant de la qualité du leadership un nouvel indicateur de succès, StarLeader vise à combiner performance durable et bien-être de l'équipe.`,
  },
  continue: `Continuer`,
  privacy_page: {
    title: `Politique de Confidentialité`,
    section1: `Chez StarLeader, protéger votre vie privée est une priorité.`,
    section2: `Lorsque vous utilisez le site www.starleader.io (le "Site"), nous pouvons collecter des données personnelles vous concernant.`,
    section3: `Le but de cette politique est de vous informer sur la manière dont nous traitons ces données en conformité avec le Règlement (UE) 2016/679 du 27 avril 2016 sur la protection des personnes physiques à l'égard du traitement des données personnelles et à la libre circulation de ces données (le "RGPD").`,
    section4: `1. Qui est le responsable du traitement des données?`,
    section5: `Lorsque vous naviguez sur notre Site, le responsable du traitement des données est Alphayz, une société S.A.S enregistrée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 952 853 109 et dont le siège social est situé au 33 rue Benoit Malon 92800 Puteaux ("Nous").`,
    section6: `En revanche, lorsque nos clients utilisent nos services, nous collectons et traitons des données personnelles pour leur compte. Nos clients sont donc responsables du traitement des données conformément à l'article 4 du RGPD. Nous agissons en tant que sous-traitant, en tant que prestataire de services.`,
    section7: `2. Quelles données collectons-nous?`,
    section8: `Les données personnelles sont des données qui peuvent être utilisées pour identifier une personne directement ou par recoupement avec d'autres données.`,
    section9: `Nous collectons des données personnelles dans les catégories suivantes:`,
    section10: `Données d'identification `,
    section11: `(nom, prénom, adresse e-mail professionnelle);`,
    section12: `Données relatives à votre vie professionnelle `,
    section13: `(nom de l'entreprise, poste/fonction);`,
    section14: `Données de connexion `,
    section15: `(logs de connexion, mots de passe chiffrés);`,
    section16: `Données de navigation `,
    section17: `(adresse IP, pages vues, date et heure de connexion, navigateur utilisé, système d'exploitation, identifiant utilisateur, IFA);`,
    section18: `Données de localisation.`,
    section19: `3. Sur quelles bases légales, à quelles fins et pendant combien de temps conservons-nous vos données personnelles?`,
    section20: `Objectifs`,
    section21: `Base légale`,
    section22: `Durée de conservation`,
    section23: `Fournir nos services disponibles sur notre Site`,
    section24: `Exécution de mesures précontractuelles prises à votre demande et/ou exécution du contrat que vous ou votre entreprise avez signé avec Nous`,
    section25: `Lorsque vous avez créé un compte: vos données sont conservées pendant la durée de votre compte. Vos logs de connexion sont conservés pendant un (1) an. Si votre compte est inactif pendant 2 ans, vos données personnelles seront supprimées si vous ne répondez pas à notre e-mail de réactivation`,
    section26: `Améliorer nos services`,
    section27: `Notre intérêt légitime à améliorer nos services`,
    section28: `Les données personnelles sont conservées pendant un (1) an`,
    section29: `Envoyer des newsletters, des sollicitations et des messages promotionnels`,
    section30: `Pour les clients: notre intérêt légitime à fidéliser nos clients et à les tenir informés de nos dernières actualités Pour les prospects: votre consentement`,
    section31: `Les données sont conservées pendant 3 ans à partir de votre dernier contact avec Nous`,
    section32: `Répondre à vos demandes d'information`,
    section33: `Notre intérêt légitime à répondre à vos demandes`,
    section34: `Les données sont conservées le temps nécessaire pour traiter votre demande d'information et sont supprimées une fois la demande traitée`,
    section35: `Établir des statistiques (de navigation, d'audience du Site, etc.) et améliorer les fonctionnalités du Site par le dépôt de cookies de mesure d'audience`,
    section36: `Notre intérêt légitime à analyser la composition de notre clientèle et à améliorer nos services`,
    section37: `Les données sont conservées pendant vingt-cinq (25) mois`,
    section38: `Gérer les demandes d'exercice de droits`,
    section39: `Notre intérêt légitime à répondre à vos demandes et à en conserver une trace`,
    section40: `Si nous vous demandons une preuve d'identité, nous ne la conserverons que le temps nécessaire pour vérifier votre identité. Une fois la vérification terminée, la preuve d'identité est supprimée.`,
    section41: `4. Qui sont les destinataires de vos données?`,
    section42: `Auront accès à vos données personnelles:`,
    section43: `Le personnel de notre société;`,
    section44: `Nos sous-traitants: hébergeur, fournisseur de mesure et d'analyse d'audience, fournisseur de services e-mail;`,
    section45: `Le cas échéant: organismes publics et privés, exclusivement pour répondre à nos obligations légales;`,
    section46: `5. Vos données seront-elles transférées hors de l'Union Européenne?`,
    section47: `Vos données sont conservées et stockées pendant la durée du traitement sur les serveurs de la société Heroku, situés dans l'Union Européenne.`,
    section48: `6. Quels sont vos droits sur vos données?`,
    section49: `Vous disposez des droits suivants sur vos données personnelles:`,
    section50: `Droit à l'information: `,
    section51: `c'est précisément la raison pour laquelle nous avons rédigé cette politique. Ce droit est prévu aux articles 13 et 14 du RGPD.`,
    section52: `Droit d'accès: `,
    section53: `vous avez le droit d'accéder à toutes vos données personnelles à tout moment, conformément à l'article 15 du RGPD.`,
    section54: `Droit de rectification: `,
    section55: `vous avez le droit de rectifier à tout moment vos données personnelles inexactes, incomplètes ou obsolètes conformément à l'article 16 du RGPD.`,
    section56: `Droit à la limitation: `,
    section57: `vous avez le droit d'obtenir la limitation du traitement de vos données personnelles dans certains cas définis à l'article 18 du RGPD.`,
    section58: `Droit à l'effacement: `,
    section59: `vous avez le droit de demander l'effacement de vos données personnelles, et d'interdire la collecte future de vos données personnelles pour les motifs énoncés à l'article 17 du RGPD.`,
    section60: `Droit d'introduire une réclamation auprès d'une autorité de contrôle compétente `,
    section61: `(en France, la CNIL), si vous estimez que le traitement de vos données personnelles constitue une violation des textes applicables, conformément à l'article 77 du RGPD`,
    section62: `Le droit de définir des directives relatives à la conservation, à l'effacement et à la communication de vos données personnelles après votre décès.`,
    section63: `Droit de retirer votre consentement à tout moment: `,
    section64: `pour les finalités basées sur le consentement, l'article 7 du RGPD prévoit que vous pouvez retirer votre consentement à tout moment. Ce retrait n'affectera pas la licéité du traitement effectué avant le retrait.`,
    section65: `Droit à la portabilité: `,
    section66: `dans certaines conditions précisées à l'article 20 du RGPD, vous avez le droit de recevoir les données personnelles que vous nous avez fournies dans un format standard lisible par machine et de demander leur transfert au destinataire de votre choix.`,
    section67: `Droit d'opposition: `,
    section68: `en vertu de l'article 21 du RGPD, vous avez le droit de vous opposer au traitement de vos données personnelles. Notez toutefois que nous pouvons continuer à les traiter malgré cette opposition, pour des raisons légitimes ou pour la défense de droits légaux.`,
    section69: `Vous pouvez exercer ces droits en nous écrivant à l'adresse ci-dessous. Nous pouvons vous demander de fournir des informations ou des documents supplémentaires pour prouver votre identité.`,
    section70: `7. Quels cookies utilisons-nous?`,
    section71: `Pour en savoir plus sur la gestion des cookies, nous vous invitons à consulter notre`,
    section72: `8. Point de contact pour exercer vos droits`,
    section73: `Adresse e-mail de contact: support@starleader.io.`,
    section74: `Adresse de contact: 33 rue Benoit Malon 92800 Puteaux FRANCE.`,
    section75: `9. Modifications`,
    section76: `Nous pouvons modifier cette politique à tout moment, notamment pour nous conformer à toute évolution réglementaire, légale, éditoriale ou technique. Ces modifications s'appliqueront à compter de la date d'entrée en vigueur de la version modifiée. Vous êtes donc invités à consulter régulièrement la dernière version de cette politique. Néanmoins, nous vous tiendrons informés de toute modification importante de cette Politique de Confidentialité.`,
    section77: `Date d'effet: 6 juin 2023`,
  },
  cookies_page: {
    title: `Politique de Cookies`,
    section1: `1. Qu'est-ce qu'un Cookie?`,
    section2: `Lors de votre navigation sur le site www.starleader.io, des cookies, pixels et autres traceurs sont déposés sur votre navigateur.`,
    section3: `Un cookie est un petit fichier, souvent crypté, stocké dans votre navigateur ou votre terminal et identifié par un nom. Il est déposé lorsque vous visitez un site. Chaque fois que vous revenez sur le site en question, le cookie est récupéré depuis votre navigateur ou terminal. Ainsi, chaque fois que vous visitez le site, le navigateur est reconnu.`,
    section4: `2. Identification des Cookies`,
    section5: `Nous pouvons utiliser des cookies techniques et de mesure d'audience. Vous en serez informé lors de votre première visite sur le Site utilisant ces cookies. Vous serez alors invité à les accepter ou à les refuser de la manière décrite ci-dessous.`,
    section6: `Cookies techniques et fonctionnels`,
    section7: `Les cookies techniques et fonctionnels sont nécessaires au bon fonctionnement du Site et à la fourniture de nos services. Ils sont utilisés tout au long de votre navigation, afin de la faciliter et d'exécuter certaines fonctions.`,
    section8: `Un cookie technique peut, par exemple, être utilisé pour se souvenir de vos réponses à un formulaire ou de vos préférences en matière de langue ou de disposition du Site, lorsque de telles options sont disponibles.`,
    section9: `Cookies de mesure d'audience`,
    section10: `Ces cookies, également appelés "cookies analytiques", nous permettent de mesurer le nombre de visites, le nombre de pages vues et l'activité des utilisateurs. Ils peuvent collecter votre adresse IP jusqu'à la ville de connexion. Les cookies de mesure d'audience nous permettent de générer des statistiques sur le nombre de visites et la navigation de notre Site afin d'améliorer notre performance. Les cookies utilisés nous permettent également d'identifier les problèmes de navigation et éventuellement de les résoudre.`,
    section11: `3. Vos préférences en matière de cookies`,
    section12: `Les cookies pouvant être déposés sans consentement`,
    section13: `Certains cookies ne nécessitent pas votre consentement, tels que:`,
    section14: ` Les cookies techniques et fonctionnels qui sont nécessaires au fonctionnement du Site;`,
    section15: ` Certains cookies de mesure d'audience ou cookies qui nous permettent de tester différentes versions du Site dans le but d'optimiser les choix éditoriaux;`,
    section16: `Acceptation ou refus des Cookies soumis à votre consentement exprès`,
    section17: `Tous les autres cookies nécessitent votre consentement. Il s'agit des cookies publicitaires, des cookies de réseaux sociaux, des cookies de personnalisation de contenu et de certains cookies de mesure d'audience. Vous pouvez librement choisir d'accepter ou de refuser l'utilisation de ces cookies.`,
    section18: `Vous pouvez accepter ou refuser ces cookies lors de votre première visite sur le Site.`,
    section19: `Vos choix d'accepter ou de refuser les cookies seront conservés pour une période de six (6) mois.`,
    section20: `Vous êtes libre de retirer votre consentement et, plus généralement, de modifier vos préférences à tout moment, via le lien de la bannière de cookies.`,
    section21: `Paramétrage de votre navigateur`,
    section22: `Il est également possible de configurer votre navigateur pour accepter ou refuser certains cookies.`,
    section23: `Chaque navigateur propose des méthodes de configuration différentes.`,
  },
  legal_page: {
    title: `Mentions Légales`,
    section1: `Éditeur:`,
    section2: `Le site www.starleader.io est édité par:`,
    section3: `La société Alphayz`,
    section4: `S.A.S au capital social de 5.000,00 euros`,
    section5: `Immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 952 853 109`,
    section6: `Siège social: 33 rue Benoit Malon 92800 Puteaux FRANCE`,
    section7: `Numéro de TVA intracommunautaire: FR88952853109`,
    section8: `Numéro de téléphone: +33 6 64 44 59 06`,
    section9: `Adresse e-mail: support@starleader.io`,
    section10: `Directeur de la publication:`,
    section11: `Mme Laura Goddard, Présidente de la société Alphayz`,
    section12: `Hébergeur:`,
    section13: `Société: Salesforce Sarl (Heroku.Inc est une société Salesforce)`,
    section14: `Siège social: Route de la Longeraie 9, Morges, 1110, Suisse, à l'attention du Directeur, EMEA Sales Operations, avec une copie à l'attention du Service Juridique, 3 Avenue Octave Gréard, 75007 Paris, France.`,
    section15: `Les serveurs Heroku utilisés par starleader.io sont situés dans l'Union Européenne.`,
  },
  goals_page: {
    title: `Objectif`,
    target: `Date cible`,
    completed: `Terminé`,
    complete: `Terminer`,
    todo: `À faire`,
    inprogress: `En cours`,
    done: `Fait`,
    no_reviews: `Il n'y a pas encore d'avis soumis pour vous. Demandez à vos employés de remplir l'avis et revenez plus tard.`,
    no_reviews_1: `Il n'y a pas encore d'avis pour vous. Demandez à vos employés`,
    no_reviews_2: `de vous évaluer`,
    no_reviews_3: `et revenez plus tard. Les scores des avis sont importants pour un objectif constructif.`,
    one_goal: `Vous pouvez créer un seul objectif`,
    create_new_goal: `Créer un nouvel objectif`,
    set_score: `Définissez votre score souhaité pour la prochaine campagne:`,
    pick_rating: `Choisissez un score supérieur à l'actuel`,
    pick_score: `Choisissez un score supérieur à l'actuel`,
    achieve_improvement: `Obtenir une amélioration en:`,
    one_month: `Un mois`,
    two_months: `Deux mois`,
    three_months: `Trois mois`,
    define_actions: `Définissez vos 3 actions:`,
    pick_action: `Choisissez une action à gauche ou saisissez-en une...`,
    chose_category: `Choisissez une catégorie dans laquelle vous souhaitez vous améliorer`,
    chose_behavior: `Choisissez le comportement que vous souhaitez améliorer`,
  },
  ratingDescription: {
    1: `pas du tout d'accord`,
    2: `pas d'accord`,
    3: `ni d'accord, ni pas d'accord`,
    4: `d'accord`,
    5: `tout à fait d'accord`,
  },
  reactions: {
    1: `Merci`,
    2: `J'adore`,
    3: `Pas d'accord`,
  },
};

export default fr;
